import { getTruckTypeLabel } from './truckSizeTypesLabels';
import { TRUCK_TYPE } from '@types';

export const getTruckTypes = [
	// Temporarily use 99 for any truck type until options are cleaned up.
	{
		label: getTruckTypeLabel(TRUCK_TYPE.ANY),
		value: 99,
	},
	{
		label: getTruckTypeLabel(TRUCK_TYPE.TIPPER),
		value: 13,
	},
	{
		label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER),
		value: 1,
	},
	{
		label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_STRAIGHT_DECK),
		value: 4,
	},
	{
		label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_DROP_DECK),
		value: 5,
	},
	{
		label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_MEZZANINE_DECK),
		value: 6,
	},
	{
		label: getTruckTypeLabel(TRUCK_TYPE.PANTECH),
		value: 2,
	},
	{
		label: getTruckTypeLabel(TRUCK_TYPE.FLAT_TOP),
		value: 3,
	},
	{
		label: getTruckTypeLabel(TRUCK_TYPE.SIDELOADER),
		value: 7,
	},
	{
		label: getTruckTypeLabel(TRUCK_TYPE.SKELETON),
		value: 8,
	},
	{
		label: getTruckTypeLabel(TRUCK_TYPE.OPEN_TOP),
		value: 10,
	}
];