import { useRecoilValue, useSetRecoilState } from 'recoil';
import { authenticatedUser, JWT } from '@state/User.ts';
import { useGetMe } from '@api/general.ts';
import { useEffect } from 'react';

const useInitAuthenticatedUser = () => {
	const {
		me,
		callGetMe
	} = useGetMe();
	const jwt = useRecoilValue(JWT);
	const setAuthUser = useSetRecoilState(authenticatedUser);

	useEffect(() => {
		if (me) {
			setAuthUser(me.data);
		}
	}, [me]);

	useEffect(() => {
		callGetMe();
	}, [jwt]);
}

export default useInitAuthenticatedUser;