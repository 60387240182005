import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RecoilRoot } from 'recoil';
import Injector from './App/Injector';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from '@components';
import ReactQueryProvider from '@utils/ReactQueryProvider';

const options = {
	init: {
		distributed_tracing: {
			enabled: true
		},
		privacy: {
			cookies_enabled: true
		},
		ajax: {
			deny_list: ["bam.nr-data.net"]
		}
	},
	info: {
		beacon: "bam.nr-data.net",
		errorBeacon: "bam.nr-data.net",
		licenseKey: import.meta.env.VITE_NEW_RELIC_LICENSE_KEY,
		applicationID: import.meta.env.VITE_NEW_RELIC_APPLICATION_ID,
		sa: 1
	},
	loader_config: {
		accountID: import.meta.env.VITE_NEW_RELIC_ACCOUNT_ID,
		trustKey: import.meta.env.VITE_NEW_RELIC_ACCOUNT_ID,
		agentID: import.meta.env.VITE_NEW_RELIC_APPLICATION_ID,
		licenseKey: import.meta.env.VITE_NEW_RELIC_LICENSE_KEY,
		applicationID: import.meta.env.VITE_NEW_RELIC_APPLICATION_ID
	}
}

new BrowserAgent(options);

const root = ReactDOM.createRoot(document.getElementById('root') as Element);

const handleError = (error: Error) => {
	//@ts-ignore
	newrelic?.noticeError && newrelic.noticeError(error);
}
root.render(
	<React.StrictMode>
		<RecoilRoot>
			<ReactQueryProvider>
				<ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
					<Injector />
				</ErrorBoundary>
			</ReactQueryProvider>
		</RecoilRoot>
	</React.StrictMode>
);
