import { useState, useEffect } from 'react';
import { TABLE_ROW_DENSITY } from '@types';
import { useLocalStorage } from '@utils';

/*
 *   useTableState requires ID for table (table name) as string
 *   and the tables column data
 */

export const useTableState = (tableId:string) => {
	const [loaded, setLoaded] = useState(false);
	/*
	 *   GLOBAL APP TABLE STATES
	 */
	const [allTableSorts, setAllTableSorts] = useState({});
	const [allTableColumnVis, setAllTableColumnVis] = useState({});
	const [allTablePins, setAllTablePins] = useState({});
	const [allTableFilters, setAllTableFilters] = useState({});
	const [allTableColumnOrder, setAllTableColumnOrder] = useState({});
	const [allTableColumnWidths, setAllTableColumnWidths] = useState({});
	const [allTableRowDensity, setAllTableRowDensity] = useLocalStorage<{[key: string]: string}>('@ofloadTableRowDensity', {});

	/*
	 *   SET & RETURN INTERNAL STATES
	 */
	const [sortModel, setSortingModel] = useState([]);
	const [columnVisibility, setColumnVisibilityModel] = useState({});
	const [tableFilters, setTableFiltersModel] = useState([]);
	const [tableColumnOrder, setTableColumnOrder] = useState([]);
	const [colWidths, setTableColWidths] = useState({});
	const [pinColumns, setPinColumnsModel] = useState({
		left: [],
		right: []
	});

	/*
	 *   useTableState Hook Methods
	 */
	const rowDensity = allTableRowDensity?.[tableId] ?? TABLE_ROW_DENSITY.REGULAR;
	const setRowDensity = (value: TABLE_ROW_DENSITY) => {
		setAllTableRowDensity((all:any) => ({
			...all,
			[tableId]: value,
		}));
	};
	const clearRowDensity = () => {
		setAllTableRowDensity((all:any) => {
			const { [tableId]: currentTable, ...withoutCurrentTable } = all;
			return withoutCurrentTable;
		});
	};

	const setColWidths = async (columns:any) => {
		const cols = columns();
		const clone:any = { ...allTableColumnWidths };
		const destructure = Object.entries(cols);
		if (clone[tableId] === undefined) {
			clone[tableId] = {};
		}
		clone[tableId][destructure[0][0]] = destructure[0][1]; // eslint-disable-line
		setTableColWidths(clone[tableId]);
		await localStorage.setItem('@ofloadTableColWidths', JSON.stringify(clone));
		setAllTableColumnWidths(clone);
	}
	const setColumnOrder = async (columns:any) => {
		const clone:any = { ...allTableColumnOrder };
		clone[tableId] = columns;
		setTableColumnOrder(columns);
		await localStorage.setItem('@ofloadTableColOrder', JSON.stringify(clone));
		setAllTableColumnOrder(clone);
	}
	const setColumnPins = async (pins:any) => {
		const clone:any = { ...allTablePins };
		setPinColumnsModel(pins);
		if (typeof pins !== 'function') {
			clone[tableId] = pins;
			await localStorage.setItem('@ofloadTableColPins', JSON.stringify(clone));
			setAllTablePins(clone);
		}
	}
	const setPinColumns = async (pins:any) => {
		const clone:any = { ...allTablePins };
		clone[tableId] = pins;
		setPinColumnsModel(pins);
		await localStorage.setItem('@ofloadTableColPins', JSON.stringify(clone));
		setAllTablePins(clone);
	}
	const setColumnVisibility = async (columns:any) => {
		const clone:any = { ...allTableColumnVis };
		clone[tableId] = columns;
		setColumnVisibilityModel(columns);
		await localStorage.setItem('@ofloadTableColVisibility', JSON.stringify(clone));
		setAllTableColumnVis(clone);
	}
	const setSortModel = async (newSortModel:any) => {
		const clone:any = { ...allTableSorts };
		clone[tableId] = newSortModel;
		setSortingModel(newSortModel);
		await localStorage.setItem('@ofloadTableSortOrder', JSON.stringify(clone));
		setAllTableSorts(clone);
	}
	const setTableFilters = async (filters:any) => {
		const cloned:any = { ...allTableFilters }
		cloned[tableId] = filters;
		await localStorage.setItem('@ofloadTableFilters', JSON.stringify(cloned));
		setTableFiltersModel(filters);
	}
	const clearTableState = async (options:any = null) => {
		let exceptions
		if (options && options.exceptions) exceptions = options.exceptions; // eslint-disable-line
		const sortsClone:any = { ...allTableSorts };
		const visClone:any = { ...allTableColumnVis };
		const pinsClone:any = { ...allTablePins };
		const filtersClone:any = { ...allTableFilters };
		const colWidthClone:any = { ...allTableColumnWidths };
		const colOrderClone:any = { ...allTableColumnOrder };
		if (!exceptions || !exceptions?.sort) delete sortsClone[tableId];
		if (!exceptions || !exceptions?.visible) delete visClone[tableId];
		if (!exceptions || !exceptions?.pins) delete pinsClone[tableId];
		if (!exceptions || !exceptions?.filter) delete filtersClone[tableId];
		if (!exceptions || !exceptions?.width) delete colWidthClone[tableId];
		if (!exceptions || !exceptions?.order) delete colOrderClone[tableId];
		await localStorage.setItem('@ofloadTableSortOrder', JSON.stringify(sortsClone));
		await localStorage.setItem('@ofloadTableColVisibility', JSON.stringify(visClone));
		await localStorage.setItem('@ofloadTableColPins', JSON.stringify(pinsClone));
		await localStorage.setItem('@ofloadTableFilters', JSON.stringify(filtersClone));
		await localStorage.setItem('@ofloadTableColWidths', JSON.stringify(colWidthClone));
		await localStorage.setItem('@ofloadTableColOrder', JSON.stringify(colOrderClone));
		if (!exceptions || !exceptions?.sort) setSortingModel([]);
		if (!exceptions || !exceptions?.visible) setColumnVisibilityModel({});
		if (!exceptions || !exceptions?.filter) setTableFiltersModel([]);
		if (!exceptions || !exceptions?.pins) {
			setPinColumnsModel({
				left: [],
				right: []
			});
		}
		if (!exceptions || !exceptions?.density) clearRowDensity();
		if (!exceptions || !exceptions?.width) setTableColWidths({});
		if (!exceptions || !exceptions?.order) setTableColumnOrder([]);
	}

	/*
	 *   INIT & SETUP...
	 */
	useEffect(() => {
		let isMounted = true;
		const getFromStorage = async () => {
			const savedSortModel = await JSON.parse(localStorage.getItem('@ofloadTableSortOrder') ?? '{}');
			const savedColVis = await JSON.parse(localStorage.getItem('@ofloadTableColVisibility') ?? '{}');
			const savedPins = await JSON.parse(localStorage.getItem('@ofloadTableColPins') ?? '{}');
			const savedFilters = await JSON.parse(localStorage.getItem('@ofloadTableFilters') ?? '{}');
			const savedColOrder = await JSON.parse(localStorage.getItem('@ofloadTableColOrder') ?? '{}');
			const savedColWidths = await JSON.parse(localStorage.getItem('@ofloadTableColWidths') ?? '{}');
			if (isMounted) {
				setAllTableSorts(savedSortModel);
				setAllTableColumnVis(savedColVis);
				setAllTablePins(savedPins);
				setAllTableFilters(savedFilters);
				setAllTableColumnOrder(savedColOrder);
				setAllTableColumnWidths(savedColWidths);
				if (savedSortModel[tableId]) {
					setSortingModel(savedSortModel[tableId]);
				}
				if (savedColVis[tableId]) {
					setColumnVisibilityModel(savedColVis[tableId]);
				}
				if (savedPins[tableId]) {
					setPinColumnsModel(savedPins[tableId]);
				}
				if (savedFilters[tableId]) {
					setTableFiltersModel(savedFilters[tableId]);
				}
				if (savedColOrder[tableId]) {
					setTableColumnOrder(savedColOrder[tableId]);
				}
				if (savedColWidths[tableId]) {
					setTableColWidths(savedColWidths[tableId]);
				}
				setLoaded(true);
			}
		}
		getFromStorage()
		return (() => {
			isMounted = false;
		})
	}, [tableId]);

	return {
		clearTableState,
		setSortModel,
		sortModel,
		columnVisibility,
		setColumnVisibility,
		pinColumns,
		setPinColumns,
		setColumnPins,
		tableFilters,
		setTableFilters,
		tableColumnOrder,
		setColumnOrder,
		colWidths,
		setColWidths,
		rowDensity,
		setRowDensity,
		loaded
	};
}
