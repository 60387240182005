import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import { formatDate } from '@utils';

declare global {
    interface Date {
        GetFirstDayOfWeek: () => Date;
        GetLastDayOfWeek: () => Date;
    }
}

/* eslint-disable */
Date.prototype.GetFirstDayOfWeek = function () {
	return (new Date(this.setDate(this.getDate() - this.getDay()+ (this.getDay() === 0 ? -6:1) )));
}
Date.prototype.GetLastDayOfWeek = function () {
	return (new Date(this.setDate(this.getDate() - this.getDay() +7)));
}
/* eslint-enable */

const isEmpty = (v:any) => {
	return v === undefined || v === null || v === '' || v.length === 0;
}

const FilterQueryBuilder = (filter:any, linkOperator?:'and'|'or') => {
	const newFilters = {
		linkOperator: linkOperator ?? 'and',
		items: [] as any,
		filterID: filter.id
	};
	const filterArr = Object.entries(filter);
	for (let i=0, l=filterArr.length; i<l; ++i) {
		if (filterArr[i][0] === 'filterName' || filterArr[i][0] === 'id') continue;
		const [, current]:any = filterArr[i];
		if (isEmpty(current) || isEmpty(current.columnField) || isEmpty(current.operatorValue)) continue;
		if (current.operatorValue === 'customPartialExpired') {
			if (current.value !== '' && current.value !== 'All') {
				newFilters.items.push({
					isCustomPartialExpired: true,
					columnField: current.columnField,
					operatorValue: '',
					value: current.value === 'Expired' ? '1' : '0'
				});
			}
		} else if (current.operatorValue === 'range') {
			if (current.value[0] !== '') {
				newFilters.items.push({
					columnField: current.columnField,
					operatorValue: '>=',
					value: current.value[0]
				})
			}
			if (current.value[1]) {
				newFilters.items.push({
					columnField: current.columnField,
					operatorValue: '<=',
					value: current.value[1]
				});
			}
		} else if (current.operatorValue === 'date') {
			const selected = current.value.dateSelect;
			const inputed = current.value.inputDate;
			const today = new Date();
			if (selected === 'Yesterday') {
				newFilters.items.push({
					columnField: current.columnField,
					operatorValue: 'Is',
					value: formatDate(subDays(today, 1), 'API_DATE_FORMAT')
				});
			} else if (selected === 'This Week') {
				newFilters.items.push({
					columnField: current.columnField,
					operatorValue: '>=',
					value: formatDate(today.GetFirstDayOfWeek(), 'API_DATE_FORMAT')
				});
				newFilters.items.push({
					columnField: current.columnField,
					operatorValue: '<=',
					value: formatDate(today.GetLastDayOfWeek(), 'API_DATE_FORMAT')
				});
			} else if (selected === 'Last 7 Days') {
				newFilters.items.push({
					columnField: current.columnField,
					operatorValue: '>=',
					value: formatDate(subDays(today, 7), 'API_DATE_FORMAT')
				});
				newFilters.items.push({
					columnField: current.columnField,
					operatorValue: '<=',
					value: formatDate(today, 'API_DATE_FORMAT')
				});
			} else if (selected === 'Next 7 Days') {
				newFilters.items.push({
					columnField: current.columnField,
					operatorValue: '>=',
					value: formatDate(today, 'API_DATE_FORMAT')
				});
				newFilters.items.push({
					columnField: current.columnField,
					operatorValue: '<=',
					value: formatDate(addDays(today, 7), 'API_DATE_FORMAT')
				});
			} else if (selected === 'This Month') {
				const month = today.getMonth();
				const year = today.getFullYear();
				const firstOfMonth = new Date(year, month, 1);
				const lastOfMonth = new Date(year, month + 1, 0);
				newFilters.items.push({
					columnField: current.columnField,
					operatorValue: '>=',
					value: formatDate(firstOfMonth, 'API_DATE_FORMAT')
				});
				newFilters.items.push({
					columnField: current.columnField,
					operatorValue: '<=',
					value: formatDate(lastOfMonth, 'API_DATE_FORMAT')
				});
			} else if (selected === 'Last 30 Days') {
				newFilters.items.push({
					columnField: current.columnField,
					operatorValue: '>=',
					value: formatDate(subDays(today, 30), 'API_DATE_FORMAT')
				});
			} else if (selected === 'This Year') {
				const year = today.getFullYear();
				const firstOfYear = new Date(year, 0, 1);
				const lastOfYear = new Date(year, 11, 31);
				newFilters.items.push({
					columnField: current.columnField,
					operatorValue: '>=',
					value: formatDate(firstOfYear, 'API_DATE_FORMAT')
				});
				newFilters.items.push({
					columnField: current.columnField,
					operatorValue: '<=',
					value: formatDate(lastOfYear, 'API_DATE_FORMAT')
				});
			} else if (selected === 'Last Year') {
				const year = today.getFullYear() - 1;
				const firstOfYear = new Date(year, 0, 1);
				const lastOfYear = new Date(year, 11, 31);
				newFilters.items.push({
					columnField: current.columnField,
					operatorValue: '>=',
					value: formatDate(firstOfYear, 'API_DATE_FORMAT')
				});
				newFilters.items.push({
					columnField: current.columnField,
					operatorValue: '<=',
					value: formatDate(lastOfYear, 'API_DATE_FORMAT')
				});
			} else if (selected === 'Date Range') {
				newFilters.items.push({
					columnField: current.columnField,
					operatorValue: '>=',
					value: isEmpty(current.value.dateFrom) ? formatDate(today, 'API_DATE_FORMAT') : formatDate(current.value.dateFrom, 'API_DATE_FORMAT')
				});
				newFilters.items.push({
					columnField: current.columnField,
					operatorValue: '<=',
					value: isEmpty(current.value.dateTo) ? formatDate(today, 'API_DATE_FORMAT') : formatDate(current.value.dateTo, 'API_DATE_FORMAT')
				});
			} else {
				newFilters.items.push({
					columnField: current.columnField,
					operatorValue: 'Is',
					value: isEmpty(inputed) ? formatDate(today, 'API_DATE_FORMAT') : formatDate(inputed, 'API_DATE_FORMAT')
				});
			}
		} else if (current.operatorValue === 'location') {
			if (!Array.isArray(current.columnField) && current.columnField.length !== 2) continue;
			for (let i=0, l=current.value.length; i<l; ++i) {
				let pickUpString = current.value[i].pickupVal;
				let dropOffString = current.value[i].dropOffVal;

				if (pickUpString && typeof pickUpString !== 'string') {
					pickUpString = pickUpString.value;
				}

				if (dropOffString && typeof dropOffString !== 'string') {
					dropOffString = dropOffString.value;
				}

				newFilters.items.push({
					isAddress: true,
					pickup: pickUpString || '',
					dropoff: dropOffString || '',
					both: current.value[i].biDir ? 1 : 0
				});
			}
		} else {
			let theValue = current.value;
			if (Array.isArray(current.value)) {
				const stringed = current.value.map((v:any) => {
					if (typeof v === 'string' || typeof v === 'number') {
						return v;
					} else {
						return v.checked ? v.value : '';
					}
				}).filter((v:any) => {
					return v !== null && v !== undefined && v !== '';
				});
				theValue = stringed.length ? stringed.join(',') : '';
			}
			if (current.value === false || current.value === 'false') {
				theValue = 0;
			}
			if (theValue !== '') {
				newFilters.items.push({
					columnField: current.columnField,
					operatorValue: current.operatorValue,
					value: theValue
				});
			}
		}
	}
	return newFilters;
}

export default FilterQueryBuilder;
