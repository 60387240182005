import styles from './FilterOptions.module.scss';
import { Text } from '@components';
import { TEXT_WEIGHT } from '@types';
import {
	FilterString,
	FilterRange,
	FilterSelectMultiple,
	FilterSelect,
	FilterDate,
	FilterLocation,
	FilterBoolean,
	FilterAssignedToMe,
	FilterShipperLookup,
	FilterUserLookup,
	FilterCarrierLookup,
	FilterSelectMultipleGroup,
	FilterCustomPartialExpired,
	FilterSelectGroup,
	FilterMultipleBoolean
} from './FilterOptions/index';
import FilterRateCardLane from './FilterOptions/FilterRateCardLane';

export type OptionProps = {
    meta: any;
    header: string;
    canFilter: boolean;
    filterData: any;
    update: (key:string, val:any) => void;
    columnId: string;
}

export const FilterOption = (props:OptionProps) => {
	const {
		meta,
		header,
		canFilter,
		filterData,
		update,
		columnId
	} = props;
	if (!meta) return null;

	const dataType = meta?.dataType;
	const fullName = meta?.columnFullName;
	const filterType = meta?.filterOptions?.type ?? 'string';

	const onChange = (v:any) => {
		update(columnId, v);
	}
	const showTitle = () => {
		return !['location', 'selectMultiple', 'boolean', 'assignedToMe', 'selectGroup', 'selectMultipleGroup'].includes(filterType);
	}
	const isFieldLookUp = () => {
		return ['shipperLookup', 'carrierLookup', 'userLookup'].includes(filterType);
	}
	const clearFilter = () => {
		onChange(null);
	}

	return (dataType === undefined || dataType === 'data') && canFilter ? <div className={styles.filterOption}>
		{
			showTitle() && <div className={styles.filterLabel}>
				<Text type={'label'} weight={TEXT_WEIGHT.BOLD} text={fullName !== undefined ? fullName : header} />
				{(filterData && isFieldLookUp()) && <button className={styles.clearBtn} onClick={() => { clearFilter() }}>Clear</button>}
			</div>
		}
		{filterType === 'boolean' && <FilterBoolean meta={meta} header={header} filterData={filterData} onChange={onChange} />}
		{filterType === 'multipleBoolean' && <FilterMultipleBoolean meta={meta} header={header} filterData={filterData} onChange={onChange} />}
		{filterType === 'assignedToMe' && <FilterAssignedToMe meta={meta} header={header} filterData={filterData} onChange={onChange} />}
		{filterType === 'string' && <FilterString meta={meta} header={header} filterData={filterData} onChange={onChange} />}
		{filterType === 'range' && <FilterRange meta={meta} header={header} filterData={filterData} onChange={onChange} />}
		{filterType === 'selectMultiple' && <FilterSelectMultiple meta={meta} header={header} filterData={filterData} onChange={onChange} />}
		{filterType === 'selectGroup' && <FilterSelectGroup meta={meta} header={header} filterData={filterData} onChange={onChange} />}
		{filterType === 'select' && <FilterSelect meta={meta} header={header} filterData={filterData} onChange={onChange} />}
		{filterType === 'date' && <FilterDate meta={meta} header={header} filterData={filterData} onChange={onChange} />}
		{filterType === 'location' && <FilterLocation meta={meta} header={header} filterData={filterData} onChange={onChange} />}
		{filterType === 'rateCardLane' && <FilterRateCardLane meta={meta} header={header} filterData={filterData} onChange={onChange} />}
		{['shipperLookup', 'shipperLookupExternalId'].includes(filterType) && <FilterShipperLookup filterType={filterType} meta={meta} header={header} filterData={filterData} onChange={onChange} />}
		{['carrierLookup', 'carrierLookupExternalId'].includes(filterType) && <FilterCarrierLookup filterType={filterType} meta={meta} header={header} filterData={filterData} onChange={onChange} />}
		{filterType === 'userLookup' && <FilterUserLookup meta={meta} header={header} filterData={filterData} onChange={onChange} />}
		{filterType === 'customPartialExpired' && <FilterCustomPartialExpired meta={meta} header={header} filterData={filterData} onChange={onChange} />}
		{filterType === 'selectMultipleGroup' && <FilterSelectMultipleGroup meta={meta} header={header} filterData={filterData} onChange={onChange} /> }
		{(filterData && !isFieldLookUp()) && <button className={styles.clearBtn} onClick={() => { clearFilter() }}>Clear</button>}
	</div> : null;
}
