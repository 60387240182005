import { useState } from 'react';
import ApiClient from './axiosSetup';
import { APIObj, AJAXError } from '@types';
import { AxiosError, GenericAbortSignal } from 'axios';

type Props = {
	endpoint: string | APIObj;
	headers?: { [key:string]: string };
	domain?: 'EOS' | 'ShipmentAPI' | 'AdminAPI' | 'Inertia';
}
type FullProps = Props & {
	method: 'GET' | 'get' | 'POST' | 'post' | 'PATCH' | 'patch' | 'DELETE' | 'delete';
}
type APICallProps = {
	payload?: any;
	params?: { [key:string]: string };
	routes?: string[];
	overrideUrl?: string;
	signal?: GenericAbortSignal;
}

/** @deprecated use from module useAPINew instead */
const useAPI = (props:FullProps) => {
	const {
		endpoint,
		method,
		headers = {},
		domain = 'EOS'
	} = props;
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [retData, setRetData] = useState<any | null>(null);
	const [error, setError] = useState<Error | AxiosError | AJAXError | null>(null);
	const fullHeaders = { ...headers }
	if (!fullHeaders['Accept']) {
		fullHeaders['Accept'] = 'application/json';
	}

	const formatURL = (props?:APICallProps): string => {
		let url = typeof endpoint === 'string' ? endpoint : endpoint.url;
		if (props && props.routes && props.routes.length) {
			for (let i=0, l=props.routes.length; i<l; ++i) {
				url = url.replace(`{${i + 1}}`, props.routes[i]);
			}
		}
		if (domain !== 'EOS') {
			url = `/${domain.toLowerCase()}${url}`;
		}
		if (props && props.overrideUrl) url = props.overrideUrl;
		return url;
	}

	const callAPI = (props?: APICallProps) => {
		setIsLoading(true);
		ApiClient.request({
			url: formatURL(props),
			method,
			data: props?.payload || {},
			params: props?.params || {},
			headers: { ...fullHeaders },
			signal: props?.signal
		}).then((res) => {
			setRetData(res.data as any);
			setIsLoading(false);
		}).catch((res: Error | AxiosError) => {
			setError(res);
			setIsLoading(false);
		});
	}

	const getData = () => {
		if (!retData) return null;
		return retData as any;
	}

	const clearError = () => {
		setError(null);
	}

	const clearData = () => {
		setRetData(null);
	}

	return {
		isLoading,
		data: getData(),
		error,
		clearError,
		clearData,
		callAPI
	} as const
}

/** @deprecated use from module useAPINew instead */
export const useGetAPI = (props:Props) => {
	const {
		endpoint,
		headers = {},
		domain = 'EOS'
	} = props;
	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = useAPI({
		endpoint,
		method: 'GET',
		headers: { ...headers },
		domain
	});
	return {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	}
}

/** @deprecated use from module useAPINew instead */
export const usePostAPI = (props:Props) => {
	const {
		endpoint,
		headers = {},
		domain = 'EOS'
	} = props;
	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = useAPI({
		endpoint,
		method: 'POST',
		headers: { ...headers },
		domain
	});
	return {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	}
}

export const usePatchAPI = (props:Props) => {
	const {
		endpoint,
		headers = {},
		domain = 'EOS'
	} = props;
	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = useAPI({
		endpoint,
		method: 'PATCH',
		headers: { ...headers },
		domain
	});
	return {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	}
}

/** @deprecated use from module useAPINew instead */
export const useDeleteAPI = (props:Props) => {
	const {
		endpoint,
		headers = {},
		domain = 'EOS'
	} = props;
	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = useAPI({
		endpoint,
		method: 'DELETE',
		headers: { ...headers },
		domain
	});
	return {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	}
}
